import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProductsThunk } from "./ProductThunk";

import {
  ProductLocalStorage,
  IdSubCategoryLocalStorage,
  ProductDetailsLocalStorage,
} from "../../util/localStorage";
const initialState = {
  page:"0",
  idSize:"",
  idSizetw:"",
  idSizeshoes:"",
  sizefilter:[],
  bottomfilter:[],
  topfilter:[],
  sortby:'',
  products: [],
  productsrRej:[],
  idSubCategory: [],
  idCondition: "",
  isLoading: true,
  docRef: "",
  details: "",
  profile:[],
  users: [],
  userstrusted:[],
  topicksArray: [],
  DropsArray: [],
  justView: [],
  productSeller: [],
  alsoLikeProducts: [],
  comments: [],
  reviews: [],
  libasProduct: [],
  vintage: [],
  chanel: [],
  amina: [],
  chanelboy: [],
  pradaReEdition: [],
  bottega: [],
  hermes: [],
  birkin: [],
  burberry: [],
  lv: [],
  mm: [],
  gucci: [],
  dg: [],
  disel: [],
  zv: [],
  isSoldItems: false,
  isLive: true,
  isGuest: false,

  isdrops: false,
  isTopPicks: false,
  isJustView: false,
  isProductFromSeller: false,
  isProductAlsoLike: false,
  isLibas: false,
  isVintage: false,
  isChanel: false,
  isAmina: false,
  isPradaReEdition: false,
  ischanelboy: false,
  isBottega: false,
  isHermes: false,
  isBirkin: false,
  isBurberry: false,
  isLV: false,
  isMachMack: false,
  isGucci: false,
  isDG: false,
  isDisel: false,
  isZadig: false,
  isLoadingData: true,
  selectedImage: "",
  selectedImageAr: "",
  myFollowers: [],
  idSizetopsm:"S-M-L",
  idSizeshoesm:"EU",
  idSizebottomm:"S-M-L",

  idSizetopw:"S-M-L",
  idSizebottomw:"S-M-L",
  idSizeshoesw:"EU",
  loadnb:'',
  PageCount:"",
  singin:false,
  singupp:false,
  joincommunity:false,
  onlineproducts:[],
  recentlyViewedarr:[],

  loadnbnewdrops:'',
lastdoc:null,
counter:0,
closefilter:0,


};
export const getAllProducts = createAsyncThunk(
  "allproducts/getAllProducts",
  getAllProductsThunk
);

const ProductSlice = createSlice({
  name: "allproducts",
  initialState,
  reducers: {
    
    SetSelectedImage: (state, { payload }) => {
      state.selectedImage = payload;
    },
    SetSizeFilter: (state, {payload }) => {
      state.sizefilter=payload;
    },
    
    SetBottomSizeFilter: (state, {payload }) => {
      state.bottomfilter=payload;
    },
    SetTopSizeFilter: (state, {payload }) => {
      state.topfilter=payload;
    },
    SetSelectdPage: (state, { payload }) => {
      state.page = payload;
    },
   
    SetCloseFilter: (state, { payload }) => {
      state.closefilter = payload;
    },
    
    SetSelectedImageAr: (state, { payload }) => {
      state.selectedImageAr = payload;
    },
    CheckSoldItems: (state, { payload }) => {
      state.isSoldItems = payload;
    },
    CheckLiveItems: (state, { payload }) => {
      state.isLive = payload;
    },
    GetAllProduct: (state, { payload }) => {
      state.products = payload;
    },
  
    getAllProductOnline: (state, action) => {
      state.onlineproducts = action.payload;
    },
    AddItemrRecentlyViewed: (state, action) => {
      state.recentlyViewedarr = action.payload;
    },
    getCounter: (state, action) => {
      state.counter = action.payload;
    },
    getLastDoc: (state, action) => {
      state.lastdoc = action.payload;
    },
    GetAllProductRejPen: (state, { payload }) => {
      state.productsrRej = payload;
    },
    GetAllProductVintage: (state, { payload }) => {
      state.vintage = payload;
    },
    GetLibasProduct: (state, { payload }) => {
      state.libasProduct = payload;
    },
    GetProductTopPicks: (state, { payload }) => {
      state.topicksArray = payload;
    },
    GetProductNewDrops: (state, { payload }) => {
      state.DropsArray = payload;
    },

    GetProductJustViewdd: (state, { payload }) => {
      state.justView = payload;
    },
    GetAllUsers: (state,{ payload }) => {
    //   if (!state.users.some(item => item.id === action.item.id)) {
    //     return {
    //         ...state,
    //         users:[...state.users, action.item],
    //     };
    // }
    // return state;
      state.users = payload;
    },
    GetAllUsersTrusted: (state, { payload }) => {
      state.userstrusted = payload;
    },
    GetProductDetails: (state, { payload }) => {
      state.details = payload;
    },
    GetProfileDetails: (state, { payload }) => {
      state.profile = payload;
    },
    GetProductFromSeller: (state, { payload }) => {
      state.productSeller = payload;
    },
    GetProductAlsoLike: (state, { payload }) => {
      state.alsoLikeProducts = payload;
    },

    GetIdSubCtegory: (state, { payload }) => {
      const idSubCategory = [...payload];
      state.idSubCategory = idSubCategory;
      // IdSubCategoryLocalStorage(idSubCategory);
    },
    GetICondition: (state, { payload }) => {
      state.idCondition = payload;
      // IdSubCategoryLocalStorage(idSubCategory);
    },
    GetComments: (state, { payload }) => {
      state.comments = payload;
    },
    GetAllUserReviews: (state, { payload }) => {
      state.reviews = payload;
    },
    GetAllChanel: (state, { payload }) => {
      state.chanel = payload;
    },
    GetAllAmina: (state, { payload }) => {
      state.amina = payload;
    },
    GetAllPradaReEdition: (state, { payload }) => {
      state.pradaReEdition = payload;
    },
    GetAllChanelBoy: (state, { payload }) => {
      state.chanelboy = payload;
    },
    GetAllHermes: (state, { payload }) => {
      state.hermes = payload;
    },
    GetAllBottega: (state, { payload }) => {
      state.bottega = payload;
    },
    GetAllBirkin: (state, { payload }) => {
      state.birkin = payload;
    },
    GetAllBurberry: (state, { payload }) => {
      state.burberry = payload;
    },
    GetAllLV: (state, { payload }) => {
      state.lv = payload;
    },
    GetAllMM: (state, { payload }) => {
      state.mm = payload;
    },
    GetAllGucci: (state, { payload }) => {
      state.gucci = payload;
    },
    GetAllDG: (state, { payload }) => {
      state.dg = payload;
    },
    GetAllDisel: (state, { payload }) => {
      state.disel = payload;
    },
    GetAllZadig: (state, { payload }) => {
      state.zv = payload;
    },
    IsZadug: (state, { payload }) => {
      state.isZadig = payload;
    },
    IsDisel: (state, { payload }) => {
      state.isDisel = payload;
    },
    IsDG: (state, { payload }) => {
      state.isDG = payload;
    },
    IsGucci: (state, { payload }) => {
      state.isGucci = payload;
    },
    IsMM: (state, { payload }) => {
      state.isMachMack = payload;
    },
    IsLV: (state, { payload }) => {
      state.isLV = payload;
    },
    IsBurberry: (state, { payload }) => {
      state.isBurberry = payload;
    },
    IsDrops: (state, { payload }) => {
      state.isdrops = payload;
    },
    IsTopPicks: (state, { payload }) => {
      state.isTopPicks = payload;
    },
    IsJustView: (state, { payload }) => {
      state.isJustView = payload;
    },
    IsProductFromSeller: (state, { payload }) => {
      state.isProductFromSeller = payload;
    },
    IsProductAlsoLike: (state, { payload }) => {
      state.isProductAlsoLike = payload;
    },
    IsProductLibas: (state, { payload }) => {
      state.isLibas = payload;
    },
    IsVintage: (state, { payload }) => {
      state.isVintage = payload;
    },
    IsChanel: (state, { payload }) => {
      state.isChanel = payload;
    },
    IsAmina: (state, { payload }) => {
      state.isAmina = payload;
    },
    IsPradaReEdition: (state, { payload }) => {
      state.isPradaReEdition = payload;
    },
    IsChanelBoy: (state, { payload }) => {
      state.ischanelboy = payload;
    },
    IsBottega: (state, { payload }) => {
      state.isBottega = payload;
    },
    IsHermes: (state, { payload }) => {
      state.isHermes = payload;
    },
    IsBirkin: (state, { payload }) => {
      state.isBirkin = payload;
    },
    GetAllMyFollowers: (state, { payload }) => {
    
      state.myFollowers = payload;
    },
   SortProducts: (state, { payload }) => {
      
      state.sortby = payload;
      //console.log(payload);
      state.myFollowers = payload;
    },
    IsGuest: (state, { payload }) => {
      state.isGuest = payload;
    },
    LoadingNumber: (state, { payload }) => {
      state.loadnb += payload;
    },
    resetLoadingNumber: (state) => {
      state.loadnb = 30;
    },
    LoadingNumberNewDrops: (state, { payload }) => {
      state.loadnbnewdrops += payload;
    },
    resetLoadingNumberNewDrops: (state) => {
      state.loadnbnewdrops = 6;
    },
    SetPageCount: (state, { payload }) => {
      state.PageCount = payload;
    },
    SetSignIn: (state, { payload }) => {
      state.singin = payload;
    },
    SetSignUpp: (state, { payload }) => {
      state.singupp = payload;
    },
    SetJoinCoumminity: (state, { payload }) => {
      state.joincommunity = payload;
    },
    SetIsLoadingData: (state, { payload }) => {
      state.isLoadingData = payload;
    },
  },
  // extraReducers: {
  //   [getAllProducts.pending]: (state) => {
  //     state.isLoading = true;
  //   },
  //   [getAllProducts.fulfilled]: (state, { payload }) => {
  //     const products = payload;

  //     state.isLoading = false;
  //     state.products = products;
  //     ProductLocalStorage(products);
  //   },
  //   [getAllProducts.rejected]: (state, { payload }) => {
  //     state.isLoading = false;
  //   },
  // },
});

export const {
  SetIsLoadingData,
  isLoadingData,
  CheckSoldItems,
  getLastDoc,
  GetIdSubCtegory,
  GetProductDetails,
  GetProductFromSeller,
  GetProductAlsoLike,
  GetICondition,
  GetAllProduct,
  GetAllUsers,
  GetProductTopPicks,
  GetProductNewDrops,
  GetProductJustViewdd,
  GetLibasProduct,
  GetComments,
  GetAllUserReviews,
  IsDrops,
  IsTopPicks,
  IsJustView,
  IsProductFromSeller,
  IsProductAlsoLike,
  IsProductLibas,
  GetAllProductVintage,
  IsVintage,
  GetAllChanel,
  IsChanel,
  IsAmina,
  GetAllAmina,
  IsPradaReEdition,
  GetAllPradaReEdition,
  IsChanelBoy,
  GetAllChanelBoy,
  IsBottega,
  IsHermes,
  GetAllBottega,
  GetAllHermes,
  IsBirkin,
  GetAllBirkin,
  IsBurberry,
  GetAllBurberry,
  CheckLiveItems,
  GetAllLV,
  GetAllDG,
  GetAllMM,
  GetAllZadig,
  GetAllDisel,
  GetAllGucci,
  IsDG,
  IsGucci,
  IsDisel,
  IsLV,
  IsMM,
  IsZadug,
  SetSelectedImage,
  SetSelectedImageAr,
  GetProfileDetails,
  GetAllMyFollowers,
  GetAllProductRejPen,
  SetSelectdPage,
  SetSelectedSize,
  SetSizeFilter,
  SetBottomSizeFilter,
  SetTopSizeFilter,
  SortProducts,
  GetAllUsersTrusted,
  IsGuest,
  LoadingNumber,
  SetPageCount,
  resetLoadingNumber,
  SetSignIn,
  SetSignUpp,
  SetJoinCoumminity,
  GetAllProductOnline,
  resetLoadingNumberNewDrops,
  LoadingNumberNewDrops,
  getAllProductOnline,
  getCounter,
  SetCloseFilter,
  AddItemrRecentlyViewed

} = ProductSlice.actions;


export default ProductSlice.reducer;